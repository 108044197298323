import {
  METADATA_RESERVED,
  ATTRIBUTE_TYPES,
  SORT_OPTIONS,
  INPUT_COMPONENT_CLASS_NAME,
  WIDGET_CLASS_NAME,
  LAYOUT_CLASS_NAME,
  TOOL_CLASS_NAME,
  DISPLAY_CLASS_NAME,
  CLASS_NAME_PREFIX,
  ATTRIBUTE_NAME_TO_HIDE,
} from './constants.js';
import { t } from 'i18next'; // Import the translation function

export const isMobileDevice = () => {
  return isMobileScreen();
};

export const isMobileScreen = () => {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
};

export const generateClassName =
  (baseClass) => (component, customClassName, title) => {
    let result = `${baseClass}-${component}`;
    if (title) result += ` ${regularToKebabCase(title)}`;
    if (customClassName) result += ` ${customClassName}`;
    result += ` ${CLASS_NAME_PREFIX}-${component}`;
    return result;
  };

export const generateInputClassName = generateClassName(
  INPUT_COMPONENT_CLASS_NAME
);
export const generateWidgetClassName = generateClassName(WIDGET_CLASS_NAME);
export const generateLayoutClassName = generateClassName(LAYOUT_CLASS_NAME);
export const generateToolClassName = generateClassName(TOOL_CLASS_NAME);
export const generateDisplayClassName = generateClassName(DISPLAY_CLASS_NAME);

export const objectToQueryStr = (obj) => {
  if (!obj || !Object.keys(obj).length) return '';
  return Object.entries(obj).reduce((output, [key, val], i) => {
    if (i) output += '&';
    if (val !== undefined) output += `${key}=${val}`;
    return output;
  }, '?');
};

const isObject = (object) => object != null && typeof object === 'object';

export const shallowCompare = (value1, value2) => {
  if (typeof value1 !== typeof value2) return false;

  if (Array.isArray(value1)) {
    if (value1.length !== value2.length) return false;
    for (let i = 0; i < value1.length; i++)
      if (value1[i] !== value2[i]) return false;
  }

  if (typeof value1 !== 'object') return value1 === value2;

  const keys1 = Object.keys(value1);
  const keys2 = Object.keys(value2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (value1[key] !== value2[key]) {
      return false;
    }
  }

  return true;
};

export const deepCompare = (item1, item2) => {
  //  Are the items the same type
  if (typeof item1 !== typeof item2) return false;
  //  If that type is Array we deepCompare each item
  //  against its counter part
  //  The same arrays in different orders will fail this check
  else if (Array.isArray(item1)) {
    if (item1.length !== item2.length) return false;
    for (let i = 0; i < item1.length; i++)
      if (!deepCompare(item1[i], item2[i])) return false;
    //  If they're objects...
  } else if (isObject(item1)) {
    const keys1 = Object.keys(item1);
    const keys2 = Object.keys(item2);

    //  We makre sure they have the same keys...
    if (keys1.length !== keys2.length) {
      return false;
    }

    //  and then deep compare each value
    for (const key of keys1) {
      if (!deepCompare(item1[key], item2[key])) return false;
    }

    //  This leaves us with literals that can be
    //  compared directly
  } else if (item1 !== item2) return false;

  return true;
};

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export function safeParseJson(input) {
  try {
    return JSON.parse(input);
  } catch (e) {}
}

export function getParams(inputUrl = null) {
  const url = new URL(inputUrl || window.location.href);
  let params;
  if (!url.search.includes('?')) {
    const splittedPathname = url?.pathname?.split('/');
    const length = splittedPathname.length;
    params = splittedPathname[length - 1];
    if (params?.length > 10) {
      params = decodeParamsObj(params);
    } else {
      params = { configId: params };
    }
  } else {
    params = Object.fromEntries(
      Array.from(url.searchParams.entries()).map(([key, value]) => [
        key,
        safeParseJson(value) || value,
      ])
    );
  }
  return params;
}

export const paramsObjectToNavigationString = (params, isChina = false) => {
  return isChina ? encodeParamsObj(params) : objectToQueryStr(params);
};

export const updateStepInURL = (step) => {
  const url = new URL(window.location.href);
  url.searchParams.set('step', step);
  window.history.replaceState({}, '', url);
};

export const filterAttributesArray = (attributeName, attributes) => {
  const attributesRegExp =
    typeof attributeName === 'string'
      ? new RegExp(`/${attributeName}/`)
      : attributeName;

  return Array.isArray(attributes)
    ? attributes.filter((el) => attributesRegExp.test(el.name))
    : Object.entries(attributes).reduce(
        (output, [attrName, attr]) =>
          attributesRegExp.test(attrName)
            ? Object.assign(output, { [attrName]: attr })
            : output,
        {}
      );
};

export const attrNameToRegExp = (name) =>
  typeof name === 'string' ? new RegExp(`${name} [0-9]`) : name;

export const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export const rgbToHex = (r, g, b) =>
  '#' + [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');

export const inflateRgb = (rgbObj) =>
  Object.entries(rgbObj).reduce(
    (output, [key, value]) =>
      ['r', 'g', 'b'].includes(key)
        ? Object.assign(output, { [key]: Math.round(255 * value) })
        : output,
    {}
  );

export const deflateRgb = (rgbObj) =>
  Object.entries(rgbObj).reduce(
    (output, [key, value]) =>
      ['r', 'g', 'b'].includes(key)
        ? Object.assign(output, { [key]: value / 255 })
        : output,
    {}
  );

export const prepAttribute = (attribute) => {
  let prepped = attribute;
  // if (attribute.values[0].metadata[ATTRIBUTE_ORDER_METADATA_KEY]) {
  //     prepped = Object.assign(attribute, {
  //         values: attribute.values.sort(
  //             (a, b) =>
  //                 a.metadata[ATTRIBUTE_ORDER_METADATA_KEY] -
  //                 b.metadata[ATTRIBUTE_ORDER_METADATA_KEY]
  //         ),
  //     });
  // }
  return prepped;
};

export const dataURItoFile = (dataURI, filename) => {
  var arr = dataURI.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const getCameraPosition = (cameraApi) => ({
  position: cameraApi.getPosition(),
  quaternion: cameraApi.getQuaternion(),
});

export const setCameraPosition = (cameraApi, cameraPosition) => {
  cameraApi.setPosition(cameraPosition.position);
  cameraApi.setQuaternion(cameraPosition.quaternion);
};

export const regularToKebabCase = (str) =>
  !str?.length
    ? ''
    : str
        .split(' ')
        .filter((word) => word?.length)
        .map((word) => word.trim().toLowerCase())
        .join('-');

export const prepAttributeForComponent = (
  attribute,
  { metadataKeys, sort },
  mode = 'component'
) => {
  const {
    imgBaseUrl,
    thumbnailFromMetadata,
    priceFromMetadata,
    descriptionFromMetadata,
    sortKeyFromMetadata,
  } = {
    metadataKeys,
  };

  const thumbnailKey =
    thumbnailFromMetadata ||
    METADATA_RESERVED.thumbnailPath ||
    METADATA_RESERVED.thumbnail;
  const priceKey = priceFromMetadata || METADATA_RESERVED.price;
  const descriptionKey =
    descriptionFromMetadata || METADATA_RESERVED.description;
  const sortKey = sortKeyFromMetadata || METADATA_RESERVED.sortKey || 'name';

  let options = attribute.values;
  let selected = attribute.value;

  if (attribute.type === ATTRIBUTE_TYPES.arraySelector) {
    options = Object.entries(attribute.values).reduce(
      (output, [assetId, el]) =>
        Object.assign(output, {
          [assetId]: prepCatalogItem(el),
        }),
      {}
    );
  } else if (attribute.type === ATTRIBUTE_TYPES.asset) {
    selected = attribute.value?.assetId;
    options = attribute.values
      ? attribute.values
          .map((el) => prepCatalogItem(el))
          .sort((a, b) => {
            const fieldA = a[sortKey];
            const fieldB = b[sortKey];

            if (!Object.keys(SORT_OPTIONS).includes(sort)) return undefined;
            if (sort === SORT_OPTIONS.ascending)
              return fieldA < fieldB ? -1 : 1;
            if (sort === SORT_OPTIONS.descending)
              return fieldA < fieldB ? 1 : -1;
            return undefined;
          })
      : [];
  } else if (attribute.type === ATTRIBUTE_TYPES.color)
    selected = inflateRgb(attribute.value);

  function prepCatalogItem(item) {
    return Object.assign(
      {},
      item,
      {
        value: item.assetId,
      },
      item.metadata[thumbnailKey]
        ? !imgBaseUrl?.length &&
          (item.metadata[thumbnailKey].startsWith('#') ||
            item.metadata[thumbnailKey].startsWith('rgb'))
          ? {
              colorValue: item.metadata[thumbnailKey],
            }
          : {
              imageUrl: (imgBaseUrl || '') + item.metadata[thumbnailKey],
            }
        : undefined,
      item.metadata[priceKey]
        ? {
            price: item.metadata[priceKey],
          }
        : undefined,
      item.metadata[descriptionKey]
        ? {
            description: item.metadata[descriptionKey],
          }
        : undefined
    );
  }

  if (mode === 'component') return { selected, options };

  return { ...attribute, values: options };
};

export const selectionToConfiguration = (value, attributeType) => {
  if (!value && value !== '') return undefined;
  let updated;
  switch (attributeType) {
    case ATTRIBUTE_TYPES.number:
      updated = value;
      break;
    case ATTRIBUTE_TYPES.asset:
      if (!isNaN(value))
        updated = 'good'; //{ assetId: attributeData?.values[value]?.assetId };
      else updated = { assetId: value };
      break;
    case ATTRIBUTE_TYPES.string:
      updated = value;
      break;
    case ATTRIBUTE_TYPES.color:
      if ('r' in value) updated = deflateRgb(value);
      else updated = value;
      break;
    default:
      updated = value;
  }
  return updated;
};

export const dataURItoBlob = (dataURI) => {
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const copyToClipboard = (data) => {
  if (!data) return;
  const str = typeof data === 'string' ? data : JSON.stringify(data);
  const el = document.createElement('textarea');
  el.value = str;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const copyTextToClipboard = async (text) => {
  await navigator.clipboard.writeText(text);
};

export const findHitNode = (hitNodes, name) => {
  if (!hitNodes.length) return undefined;
  const hierarchy = [...hitNodes[0].hierarchy];
  hierarchy.reverse();

  return (
    hierarchy.find((el) =>
      typeof name === 'string' ? name === el.name : name.test(el.name)
    ) || undefined
  );
};

export const easeInOutCubic = (val) =>
  val < 0.5 ? 4 * val * val * val : 1 - Math.pow(-2 * val + 2, 3) / 2;

export const metadataValueToObject = (data) =>
  data.split(',').reduce((output, keVal) => {
    const [key, value] = keVal
      .trim()
      .split('=')
      .map((el) => el.trim());
    return Object.assign(output, { [key]: parseFloat(value) || value });
  }, {});

export const filterFormAttributes = (
  attributes,
  attributeComponents,
  includeReservedAttributes
) => {
  if (!attributes) return [];
  if (
    (!attributeComponents || !Object.keys(attributeComponents).length) &&
    includeReservedAttributes
  )
    return attributes;
  return Object.values(attributes).filter((attr) => {
    if (!attr) return false;
    if (!includeReservedAttributes && attr?.name?.[0] === '_') return false;
    if (attributeComponents && attr?.name in attributeComponents) {
      if ([undefined, false].includes(attributeComponents[attr.name]))
        return false;
    }
    return true;
  });
};

export const filterFormAttributesGrouping = (
  form,
  attributeComponents,
  includeReservedAttributes
) => {
  if (!form) return [];
  if (
    (!attributeComponents || !Object.keys(attributeComponents).length) &&
    includeReservedAttributes
  )
    return form;

  const formGroupEntries = Object.entries(form);
  return formGroupEntries.map(([groupName, attributes]) => {
    return {
      id: groupName,
      attributes: filterFormAttributes(
        attributes,
        attributeComponents,
        includeReservedAttributes
      ),
    };
  });
};

export const isEmptyObj = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isNotEmptyObj = (obj) => !isEmptyObj(obj);

export const findOptionComponent = (options, value) => {
  return options?.[value];
};

export const assetsRules = (asset) =>
  !ATTRIBUTE_NAME_TO_HIDE.includes(asset.name);

export function getDefaultAttributesValues(attributesData) {
  const defaultAttributesValues = {};
  if (attributesData) {
    Object.keys(attributesData)?.forEach((key) => {
      if (
        attributesData[key].hasOwnProperty('defaultValue') &&
        !key.includes('Object Angle')
      ) {
        defaultAttributesValues[key] = attributesData[key].defaultValue;
      }
    });
  }
  return defaultAttributesValues;
}

export const mappingLanguage = (lng) => {
  switch (lng) {
    case 'de_DE':
      return 'de_DE';

    case 'deu':
      return 'de_DE';

    case 'en_E1':
      return 'en_E1';

    case 'ENG':
      return 'en_E1';

    case 'eng':
      return 'en_E1';

    case 'es_ES':
      return 'es_ES';

    case 'esp':
      return 'es_ES';

    case 'fr_FR':
      return 'fr_FR';

    case 'fra':
      return 'fr_FR';

    case 'it_IT':
      return 'it_IT';

    case 'ita':
      return 'it_IT';

    case 'ja_JP':
      return 'ja_JP';

    case 'jpn':
      return 'ja_JP';

    case 'ko_KR':
      return 'ko_KR';

    case 'kor':
      return 'ko_KR';

    case 'pt_BR':
      return 'pt_BR';

    case 'por':
      return 'pt_BR';

    case 'ru_RU':
      return 'ru_RU';

    case 'rus':
      return 'ru_RU';

    case 'zh_CN':
      return 'zh_CN';

    case 'zhs':
      return 'zh_CN';

    case 'zh_HK':
      return 'zh_HK';

    case 'zht':
      return 'zh_HK';

    default:
      return 'en_E1';
  }
};

export const parseRecipe = (inputJson, leadTimeAndPrice) => {
  let readableConfig = [];
  let index = 0;
  for (const [key, value] of Object.entries(
    inputJson?.metadata?.readableConfiguration || {}
  )) {
    const configItem = {
      label: key,
      index: value.index || index || 0,
      value: value.value,
      thumbnailPath: value?.thumbnailPath || value?.thumbnail,
      thumbnailColor: value?.thumbnailColor,
    };
    readableConfig.push(configItem);
    index++;
  }
  const outputJson = {
    readableConfiguration: readableConfig,
    createdAt: inputJson.createdAt,
    shortId: inputJson.shortId,
    attachments:
      Object.keys(inputJson.attachments).length && inputJson.attachments,
    clientUrl: inputJson.clientUrl,
    editUrl: inputJson.editUrl,
    productName: inputJson.productName,
    inStore: inputJson.inStore,
    leadTime:
      leadTimeAndPrice?.maxLeadTime || leadTimeAndPrice?.minLeadTime || 0,
    price: leadTimeAndPrice?.price || 0,
    currency: leadTimeAndPrice?.currency || 'EUR',
    sku: inputJson.sku,
  };

  return outputJson;
};

export const priceFormatExceptions = (currency) => {
  if (currency === 'SGD') {
    return 'fr-SG';  // Force French locale for SGD
  }

  return null;
};

export const waitForDataDrivenConfigurator = async () => {
  while (
    !window.dataDrivenConfigurator ||
    !window.dataDrivenConfigurator.isInited ||
    !window?.dataDrivenConfiguratorExtension ||
    !window?.dataDrivenConfiguratorExtension?.getStatus
  ) {
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await wait(10);
  }
};

export const waitForDataDrivenExtensionConfigurator = async () => {
  while (
    !window.dataDrivenConfiguratorExtension ||
    !window.dataDrivenConfiguratorExtension.isInited
  ) {
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await wait(10);
  }
};

export const getAttributeIndex = async (attributeName) => {
  if (!attributeName) return -1;
  const status = await window?.dataDrivenConfiguratorExtension?.getStatus();
  const validAttributesAndTheirValues = status?.validAttributesAndTheirValues;
  const attributeIndex = validAttributesAndTheirValues?.findIndex(
    (attr) => attr?.name === attributeName
  );
  return attributeIndex;
};

export const calculateLeadtime = ({ min, max }) => {
  let unit, value, newMin, newMax;
  newMin = min;
  newMax = max;

  if (min <= 0 && max <= 0) return { unit: null, value: '' };

  if (min < 7) {
    unit = 'days';
  } else {
    unit = 'weeks';
    newMin = Math.ceil(min / 7);
    newMax = Math.ceil(max / 7);
  }

  if (newMin === newMax) {
    value = `${newMin}`;
  } else {
    value = `${newMin}/${newMax}`;
  }

  return { unit, value };
};

export const isEmpty = (variable) => {
  if (variable === undefined || variable === null) {
    return true;
  }
  if (typeof variable === 'number' && isNaN(variable)) {
    return true;
  }
  if (typeof variable === 'string' && variable.trim().length > 0) {
    return false;
  }
  if (Array.isArray(variable) && variable.length > 0) {
    return false;
  }
  if (typeof variable === 'object' && Object.keys(variable).length > 0) {
    return false;
  }
  return true;
};

export const sortArrayByBooleanFunction = (array, booleanFunction) => {
  if (!array || !array?.length) return [];
  const sortedArray = [...array];
  sortedArray?.sort((a, b) => {
    const aIsValid = booleanFunction(a?.name);
    const bIsValid = booleanFunction(b?.name);
    if (aIsValid && !bIsValid) {
      return -1;
    } else if (!aIsValid && bIsValid) {
      return 1;
    } else {
      return 0;
    }
  });
  return sortedArray;
};

const base64ToBytes = (base64) => {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
};
const bytesToBase64 = (bytes) => {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('');
  return btoa(binString);
};
export const encodeParamsObj = (params) => {
  return encodeURIComponent(
    bytesToBase64(new TextEncoder().encode(JSON.stringify(params)))
  );
};
export const decodeParamsObj = (params) => {
  return JSON.parse(
    new TextDecoder().decode(base64ToBytes(decodeURIComponent(params)))
  );
};

export const addOpacityAnimation = (elementId) => {
  const playerEl = document.getElementById(elementId);
  if (!playerEl) return;
  playerEl.classList.add('opacity-animation');
  setTimeout(() => {
    playerEl.classList.remove('opacity-animation');
  }, 500);
};

export const shouldDisableButton = (formRequiredAndCheckedFields) => {
  if (isEmptyObj(formRequiredAndCheckedFields)) return false;

  let result = false;
  const object = formRequiredAndCheckedFields;

  Object.keys(object).forEach((key) => {
    const inputValue = object[key];
    if (!inputValue || inputValue.length === 0) {
      result = true;
      return;
    }
  });

  return result;
};

export function translateLeadTime(leadTime, leadTimeUnit) {
  let translationKey;

  switch (leadTimeUnit) {
    case 'day_one':
      translationKey = 'stock.label.date.day_one';
      break;
    case 'days':
      translationKey = 'stock.label.date.day_other';
      break;
    case 'day_zero':
      translationKey = 'stock.label.date.day_zero';
      break;
    case 'month_one':
      translationKey = 'stock.label.date.month_one';
      break;
    case 'month_other':
      translationKey = 'stock.label.date.month_other';
      break;
    case 'week_one':
      translationKey = 'stock.label.date.week_one';
      break;
    case 'weeks':
      translationKey = 'stock.label.date.week_other';
      break;
    default:
      translationKey = 'stock.label.date.day_other'; // Add a default key if needed
  }

  return t(translationKey, { leadTime });
}
