import React, { useMemo } from 'react';
import { useWindowSize } from '../../utils/threekitHooks';
import OptionsButton from '../Form/OptionsButton';
import { FormattedNumber, IntlProvider } from 'react-intl';

import {
  Container,
  ProductSKU,
  ProductName,
  ProductPrice,
  InnerContainer,
  ShareAndLeadtime,
} from './TopSection.styles';
import { PRICE_PLACEHOLDER } from '../../utils/constants';
import { priceFormatExceptions } from '../../utils/functions'

const TopSection = ({ productData }) => {
  const { isMobile } = useWindowSize();
  const { sku, price, productName, priceCurrency } = productData;
  const priceFormated = useMemo(() => {
    const locale = priceFormatExceptions(priceCurrency);

    const formattedPrice = (
      <FormattedNumber
        style="currency"
        currency={priceCurrency}
        value={price}
      />
    );
      if (!priceCurrency) return price;
      return locale ? (
        <IntlProvider locale={locale}>
          {formattedPrice}
        </IntlProvider>
      ) : (
        formattedPrice
      );
    }, [price, priceCurrency]);

  return (
    <Container>
      {isMobile ? (
        <>
          <ProductSKU>{sku}</ProductSKU>
          <OptionsButton />
          <ProductName>{productName}</ProductName>
          <ProductPrice>{priceFormated || PRICE_PLACEHOLDER}</ProductPrice>
        </>
      ) : (
        <>
          <InnerContainer>
            <ProductSKU>{sku}</ProductSKU>
            <ProductName>{productName}</ProductName>
            <ProductPrice>{priceFormated || PRICE_PLACEHOLDER}</ProductPrice>
          </InnerContainer>
          <ShareAndLeadtime>
            <OptionsButton />
          </ShareAndLeadtime>
        </>
      )}
    </Container>
  );
};

export default TopSection;
